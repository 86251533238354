import React, { useState, useEffect } from "react"
import { footerNavigationTree } from "../../config/navigation"
import YoutubeModal from "./YoutubeModal"
import { Box, Flex, Heading, useTheme } from "@chakra-ui/react"
import TextLink from "./TextLink"
import styles from "./css/Footer.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useColors from "../../common/hooks/useColors"
import { useAuth } from "../contexts/AuthContext"
import { useRouter } from "next/router"

const scrollPosForFooter = 1200

const Footer = () => {
  const { loggedInUser } = useAuth()
  const router = useRouter()
  const { blueColor } = useColors()
  const [scrollTop, setScrollTop] = useState(0)
  const [isModalVideoOpen, setIsModalVideoOpen] = useState(false)
  const [modalVideoId, setModalVideoId] = useState()

  const isIndexLoading = router.pathname === "/" && !loggedInUser?._id
  // have footer come up eventually
  useEffect(() => {
    function onScroll() {
      setScrollTop(
        window.location.pathname === "/feed" || window.location.pathname === "/"
          ? document.body.scrollTop || document.documentElement.scrollTop
          : 0,
      )
    }
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  if (isIndexLoading) {
    return null
  }
  return (
    <div
      className={
        scrollTop > scrollPosForFooter
          ? styles["footer-fixed"]
          : styles["footer"]
      }
      style={{
        bottom: 0,
      }}
    >
      <div className={styles["container"]}>
        <Heading as="h6" size="sm" position="absolute" bottom={2} left={10}>
          &copy;{new Date().getFullYear()} Pebble Climbing
        </Heading>
        <Box display="flex">
          {footerNavigationTree.map((col) => (
            <div key={col.title} className={styles["section"]}>
              <Heading as="h3" size="md">
                {col.title}
              </Heading>
              <Flex
                direction="column"
                flexWrap={"wrap"}
                maxHeight={["auto", 100, 100]}
              >
                {col.sections.map((sect) => {
                  return (
                    <Box my={1} key={sect.title}>
                      {sect.youtubeId ? (
                        <Flex align="center">
                          <Heading
                            as="h6"
                            size="sm"
                            variant="link"
                            isunderline="true"
                            key={sect.title}
                            onClick={() => {
                              setIsModalVideoOpen(true)
                              setModalVideoId(sect.youtubeId)
                            }}
                          >
                            {sect.title}
                          </Heading>
                        </Flex>
                      ) : (
                        <Flex align="center">
                          <Heading
                            as="h6"
                            size="sm"
                            key={sect.title}
                            color="brand.blueColor"
                          >
                            {sect.icon && (
                              <Box as="span" mr={2} display="inline-block">
                                <FontAwesomeIcon
                                  icon={sect.icon}
                                  color={blueColor}
                                />
                              </Box>
                            )}
                            <TextLink
                              href={
                                sect.link?.includes("http") ? sect.link : null
                              }
                              to={
                                sect.link?.includes("http") ? null : sect.link
                              }
                            >
                              {sect.title}
                            </TextLink>
                          </Heading>
                        </Flex>
                      )}
                    </Box>
                  )
                })}
              </Flex>
            </div>
          ))}
        </Box>
      </div>
      <YoutubeModal
        isOpen={isModalVideoOpen}
        youtubeId={modalVideoId}
        closeModal={() => setIsModalVideoOpen(false)}
      />
    </div>
  )
}

export default Footer

import gql from "graphql-tag"
import { basicGuideFragment } from "./basicGuideFragment"
import { basicAreaFragment } from "./basicAreaFragment"
import { mediumRouteFragment } from "./mediumRouteFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { basicSendFragment } from "../../sends/graphql/basicSendFragment"
import { basicGymFragment } from "../../gyms/graphql/basicGymFragment"
import { basicGymAreaFragment } from "../../gyms/graphql/basicGymAreaFragment"
import { basicRouteFragment } from "./basicRouteFragment"

export const fullRouteFragment = gql`
  fragment fullRouteFragment on Route {
    popMedia {
      ...basicMediaFragment
    }
    isNameEditable
    beta
    startingHoldPicture
    arBetas {
      picture
      description
      editor {
        _id
      }
    }
    version
    popularity
    numUserSends
    firstAscent {
      ...basicSendFragment
    }
    pitches {
      _id
      name
      grade
      grades {
        user
        grade
      }
      gear
      description
      numberOfBolts
      height

      kneebar
      pumpy
      powerful
      crimpy
      slopey
      pinchy
      juggy
      pockety
      bigFeet
      smallFeet
      compression
      tension
      dynamic
      static
      slab
      vertical
      overhang
      steep
      roof
      arete
      dihedral
      crack
    }

    sends {
      ...basicSendFragment
    }
    editors {
      date
      version
      user {
        _id
      }
    }
    media {
      ...basicMediaFragment
    }
    area {
      ...basicAreaFragment
      parentArea {
        ...basicAreaFragment
        parentArea {
          ...basicAreaFragment
        }
      }
    }
    guide {
      ...basicGuideFragment
    }
    gym {
      ...basicGymFragment
    }
    gymArea {
      ...basicGymAreaFragment
      parentArea {
        ...basicGymAreaFragment
        parentArea {
          ...basicGymAreaFragment
        }
      }
    }
    betaVideo {
      ...basicMediaFragment
    }
    oembed3d {
      html
      url
      thumbnailUrl
    }
    scanner {
      _id
      batteryLevel
    }
    ...mediumRouteFragment
  }
  ${mediumRouteFragment}
  ${basicSendFragment}
  ${basicMediaFragment}
  ${basicAreaFragment}
  ${basicGuideFragment}
  ${basicGymFragment}
  ${basicGymAreaFragment}
`

export const RouteQuery = gql`
  query RouteQuery($slug: String!, $id: String) {
    route(slug: $slug, id: $id) {
      ...fullRouteFragment
    }
  }
  ${fullRouteFragment}
`

export const BASIC_ROUTE = gql`
  query BasicRoute($slug: String!, $id: String) {
    route(slug: $slug, id: $id) {
      ...basicRouteFragment
      gymArea {
        ...basicGymAreaFragment
      }
    }
  }
  ${basicRouteFragment}
  ${basicGymAreaFragment}
`

export const EDIT_ROUTE = gql`
  mutation EditRoute($route: RouteInput!) {
    editRoute(route: $route) {
      ...fullRouteFragment
    }
  }
  ${fullRouteFragment}
`
export const CREATE_ROUTE = gql`
  mutation CreateRoute($route: RouteInput!) {
    createRoute(route: $route) {
      ...fullRouteFragment
    }
  }
  ${fullRouteFragment}
`

export const DELETE_ROUTE = gql`
  mutation DeleteRoute($routeId: String!) {
    deleteRoute(routeId: $routeId) {
      ...fullRouteFragment
    }
  }
  ${fullRouteFragment}
`

export const setRouteQualityMutate = gql`
  mutation setRouteQualityMutate(
    $routeId: String!
    $userId: String!
    $quality: Float!
    $isRatingInCompSet: Boolean
  ) {
    setRouteQuality(
      routeId: $routeId
      userId: $userId
      quality: $quality
      isRatingInCompSet: $isRatingInCompSet
    ) {
      _id
      quality
      qualities {
        user
        stars
      }
    }
  }
`

export const SET_ROUTE_GRADE = gql`
  mutation SetRouteGrade($routeId: String!, $grade: Float!) {
    setRouteGrade(routeId: $routeId, grade: $grade) {
      _id
      grade
      grades {
        user
        grade
      }
    }
  }
`

export const EDIT_ROUTE_SETTER_PICTURE_LOCATION = gql`
  mutation EditRouteSetterPictureLocation(
    $routeId: String!
    $x: Float!
    $y: Float!
  ) {
    editRouteSetterPictureLocation(routeId: $routeId, x: $x, y: $y) {
      _id
      setterPictureLocation {
        x
        y
      }
    }
  }
`
export const EDIT_ROUTE_HOLD_LOCATIONS = gql`
  mutation EditRouteHoldLocations(
    $routeId: String!
    $holdLocations: [ImageLocationInput]
  ) {
    editRouteHoldLocations(routeId: $routeId, holdLocations: $holdLocations) {
      _id
      holdLocations {
        x
        y
      }
    }
  }
`
export const SAVE_ROUTE_CHARACTERISTICS = gql`
  mutation SaveRouteCharacteristics($route: RouteInput!) {
    saveRouteCharacteristics(route: $route) {
      ...mediumRouteFragment
    }
  }
  ${mediumRouteFragment}
`

export const SAVE_ROUTE_LOCATION = gql`
  mutation SaveRouteLocation($routeId: String!, $location: PointInput!) {
    saveRouteLocation(routeId: $routeId, location: $location) {
      ...basicRouteFragment
    }
  }
  ${basicRouteFragment}
`

export default {
  fullRouteFragment,
  RouteQuery,
  CREATE_ROUTE,
  EDIT_ROUTE,
  DELETE_ROUTE,
  setRouteQualityMutate,
  SET_ROUTE_GRADE,
}

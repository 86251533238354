import gql from "graphql-tag"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { basicSendFragment } from "../../sends/graphql/basicSendFragment"
import { basicAreaFragment } from "./basicAreaFragment"
import { mediumProblemFragment } from "./mediumProblemFragment"
import { basicGuideFragment } from "./basicGuideFragment"
import { basicGymFragment } from "../../gyms/graphql/basicGymFragment"
import { basicGymAreaFragment } from "../../gyms/graphql/basicGymAreaFragment"
import { basicProblemFragment } from "./basicProblemFragment"

export const fullProblemFragment = gql`
  fragment fullProblemFragment on Problem {
    popMedia {
      ...basicMediaFragment
    }
    isNameEditable
    beta
    startingHoldPicture
    arBetas {
      picture
      description
      editor {
        _id
      }
    }
    version
    numUserSends
    firstAscent {
      ...basicSendFragment
    }
    editors {
      date
      version
      user {
        _id
      }
    }
    sends {
      ...basicSendFragment
    }
    media {
      ...basicMediaFragment
    }
    area {
      ...basicAreaFragment
      parentArea {
        ...basicAreaFragment
        parentArea {
          ...basicAreaFragment
        }
      }
    }
    guide {
      ...basicGuideFragment
    }
    gym {
      ...basicGymFragment
    }
    gymArea {
      ...basicGymAreaFragment
      parentArea {
        ...basicGymAreaFragment
        parentArea {
          ...basicGymAreaFragment
        }
      }
    }
    betaVideo {
      ...basicMediaFragment
    }
    oembed3d {
      html
      url
      thumbnailUrl
      creator {
        _id
      }
    }
    scanner {
      _id
      batteryLevel
    }
    ...mediumProblemFragment
  }
  ${mediumProblemFragment}
  ${basicSendFragment}
  ${basicMediaFragment}
  ${basicAreaFragment}
  ${basicGuideFragment}
  ${basicGymFragment}
  ${basicGymAreaFragment}
`

export const ProblemQuery = gql`
  query ProblemQuery($slug: String!, $id: String) {
    problem(slug: $slug, id: $id) {
      ...fullProblemFragment
    }
  }
  ${fullProblemFragment}
`

export const BASIC_PROBLEM = gql`
  query BasicProblem($slug: String!, $id: String) {
    problem(slug: $slug, id: $id) {
      ...basicProblemFragment
      gymArea {
        ...basicGymAreaFragment
      }
    }
  }
  ${basicProblemFragment}
  ${basicGymAreaFragment}
`

// TODO: return any properties that could be updated and mutation will auto update
export const EDIT_PROBLEM = gql`
  mutation EditProblem($problem: ProblemInput!) {
    editProblem(problem: $problem) {
      ...fullProblemFragment
    }
  }
  ${fullProblemFragment}
`
export const CREATE_PROBLEM = gql`
  mutation CreateProblem($problem: ProblemInput!) {
    createProblem(problem: $problem) {
      ...fullProblemFragment
    }
  }
  ${fullProblemFragment}
`

export const DELETE_PROBLEM = gql`
  mutation DeleteProblem($problemId: String!) {
    deleteProblem(problemId: $problemId) {
      ...fullProblemFragment
    }
  }
  ${fullProblemFragment}
`

export const setProblemQualityMutate = gql`
  mutation setProblemQualityMutate(
    $problemId: String!
    $userId: String!
    $quality: Float!
    $isRatingInCompSet: Boolean
  ) {
    setProblemQuality(
      problemId: $problemId
      userId: $userId
      quality: $quality
      isRatingInCompSet: $isRatingInCompSet
    ) {
      _id
      type
      quality
      qualities {
        user
        stars
      }
    }
  }
`

export const reportFeatureMutate = gql`
  mutation reportFeatureMutate(
    $featureType: String!
    $featureSlug: String!
    $featureVersion: Int!
    $message: String!
  ) {
    reportFeature(
      featureType: $featureType
      featureSlug: $featureSlug
      featureVersion: $featureVersion
      message: $message
    ) {
      isSuccessful
      error
    }
  }
`

export const SET_PROBLEM_GRADE = gql`
  mutation SetProblemGrade($problemId: String!, $grade: Float!) {
    setProblemGrade(problemId: $problemId, grade: $grade) {
      _id
      grade
      grades {
        user
        grade
      }
    }
  }
`

export const EDIT_PROBLEM_SETTER_PICTURE_LOCATION = gql`
  mutation EditProblemSetterPictureLocation(
    $problemId: String!
    $x: Float!
    $y: Float!
  ) {
    editProblemSetterPictureLocation(problemId: $problemId, x: $x, y: $y) {
      _id
      setterPictureLocation {
        x
        y
      }
    }
  }
`

export const EDIT_PROBLEM_HOLD_LOCATIONS = gql`
  mutation EditProblemHoldLocations(
    $problemId: String!
    $holdLocations: [ImageLocationInput]
  ) {
    editProblemHoldLocations(
      problemId: $problemId
      holdLocations: $holdLocations
    ) {
      _id
      holdLocations {
        x
        y
      }
    }
  }
`

export const SAVE_PROBLEM_CHARACTERISTICS = gql`
  mutation SaveProblemCharacteristics($problem: ProblemInput!) {
    saveProblemCharacteristics(problem: $problem) {
      ...mediumProblemFragment
    }
  }
  ${mediumProblemFragment}
`

export const SAVE_PROBLEM_LOCATION = gql`
  mutation SaveProblemLocation($problemId: String!, $location: PointInput!) {
    saveProblemLocation(problemId: $problemId, location: $location) {
      ...basicProblemFragment
    }
  }
  ${basicProblemFragment}
`

export default {
  fullProblemFragment,
  ProblemQuery,
  CREATE_PROBLEM,
  EDIT_PROBLEM,
  DELETE_PROBLEM,
  setProblemQualityMutate,
  SET_PROBLEM_GRADE,
}

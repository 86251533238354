import gql from "graphql-tag"
import { basicUserFragment } from "./basicUserFragment"
import { basicMediaFragment } from "../../media/graphql/basicMediaFragment"
import { basicCompFragment } from "../../gyms/graphql/comps"

// Fragments

export const profileFragment = gql`
  fragment profileFragment on Profile {
    _id
    picture
    gender
    location
    website
    bio
    shoes
    latestShoes
    harness
    rope
    cover {
      slug
    }
  }
`

export const achievementFragment = gql`
  fragment achievementFragment on Achievement {
    _id
    type
    name
    description
    value
    achievementType
    categoryOrder
    date
    badge
    isIndoor
    isCustom
    points
    send {
      _id
      name
      slug
      sendType
      isGrade
    }
    guide {
      _id
      name
      slug
      type
      popMedia {
        ...basicMediaFragment
      }
    }
    gym {
      _id
      slug
      name
      type
      isOverlappingGrades
      isShowingGrades
      popMedia {
        ...basicMediaFragment
      }
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
    problem {
      _id
      name
      slug
    }
    route {
      _id
      name
      slug
    }
  }
  ${basicMediaFragment}
`

export const loggedInUserFragment = gql`
  fragment loggedInUserFragment on User {
    _id
    awaitingFollowers
    awaitingFollowing
    earliestMediaDate
    earliestSendDate
    email
    firstName
    hasClimbTags
    hasMedia
    hasPhotoTags
    hasRoutesAndProblems
    hasSends
    isAdmin
    isBetaUser
    isBanned
    isScrambledUser
    isFrenchGrades
    isPrivate
    isSponsor
    lastName
    latestMediaDate
    latestSendDate
    totalAscents
    name
    slug
    last4
    stripeSubscriptionInfo {
      id
      status
    }
    stripeCustomerId
    hasTriedStripeSubscription
    stripeConnectedAccountId
    areStripeChargesEnabled
    isAllowedToSubscribe
    profile {
      ...profileFragment
      height
      weight
      age
      dob
    }
    sends {
      slug
      sendType
    }
    media {
      slug
    }
    followers {
      ...basicUserFragment
    }
    following {
      ...basicUserFragment
    }
    blockers {
      ...basicUserFragment
    }
    blocking {
      ...basicUserFragment
    }
    isGymOwner
    totalGymsAllowed
    gymsOwned
    gymsIsOwnerOf {
      _id
      slug
      name
      type
      isHidden
      isBeta
      logo
      popMedia {
        ...basicMediaFragment
      }
      hasScanners
      isTopoMapStarted
      isTopoMapEnabled
      hasPremiumPlan
    }
    gymsIsManagerOrHeadSetterOf {
      _id
      slug
      name
      type
      isHidden
      isBeta
      logo
      popMedia {
        ...basicMediaFragment
      }
    }
    gymTrialEndDate
    gymsManaged
    gymsCoachAt
    gymsEmployeeAt
    gymsHeadSetterAt
    gymsSetterAt
    gymsMaxGradesSetAt {
      gymId
      problemGrade
      routeGrade
      canResetArea
    }
    gymMembershipRewards {
      _id
      gymId
      rewardId
      rewardName
      gymGroup {
        _id
      }
    }
    brandsRepresenting {
      _id
      slug
      name
      logo
      isHidden
    }
    gymsFollowing
    isFollowingTheFront
    isRunningAmenitiesChallenge
    gymsFollowingForMeetups {
      _id
      slug
      name
      isTopoMapStarted
      isTopoMapEnabled
      hasScanners
    }
    instagramMediaAuthorization {
      token
      id
    }
    notificationTokens {
      _id
      token
      os
    }
    notifications {
      _id
      users {
        ...basicUserFragment
      }
      name
      noteType
      link
      isSeen
      isOpened
      date
    }
    numWorkouts
    isAllowingDataToGym
    isGymNotificationsEnabled
    isCommentSubscriptionDisabled
    hardestCurrentOutdoorRouteSendGrade
    hardestCurrentOutdoorProblemSendGrade
    hardestCurrentIndoorRouteSendGrade
    hardestCurrentIndoorProblemSendGrade
    hasDoneOutdoorProblemSends
    hasDoneOutdoorRouteSends
    rfid
    isSetterNotificationsEnabled
    problemToLinkWithWristband
    routeToLinkWithWristband
    stravaAccessToken
    stravaRefreshToken
    isRewardPointsEnabled
    isDisablingKeepAwake
    hasUpcomingComps
    isRunningAmenitiesChallenge
    hasPremiumAccess
  }
  ${basicUserFragment}
  ${profileFragment}
  ${basicMediaFragment}
`

export const fullUserFragment = gql`
  fragment fullUserFragment on User {
    _id
    awaitingFollowers
    awaitingFollowing
    earliestMediaDate
    earliestSendDate
    email
    firstName
    hasClimbTags
    hasMedia
    hasPhotoTags
    hasRoutesAndProblems
    hasSends
    isAdmin
    isBanned
    isScrambledUser
    isFrenchGrades
    isPrivate
    isSponsor
    lastName
    latestMediaDate
    latestSendDate
    totalAscents
    name
    slug
    profile {
      ...profileFragment
    }
    sends {
      slug
      sendType
    }
    media {
      slug
    }
    followers {
      ...basicUserFragment
    }
    following {
      ...basicUserFragment
    }
    blockers {
      ...basicUserFragment
    }
    blocking {
      ...basicUserFragment
    }
    gymMembershipRewards {
      _id
      gymId
      rewardId
      rewardName
    }
    brandsRepresenting {
      _id
      slug
      name
      logo
      isHidden
    }
    isGymOwner
    totalGymsAllowed
    gymsOwned
    gymsManaged
    gymsCoachAt
    gymsEmployeeAt
    gymsHeadSetterAt
    gymsSetterAt
    gymsMaxGradesSetAt {
      gymId
      problemGrade
      routeGrade
      canResetArea
    }
    gymsFollowing
    numWorkouts
    hasDoneOutdoorProblemSends
    hasDoneOutdoorRouteSends
    hardestCurrentOutdoorRouteSendGrade
    hardestCurrentOutdoorProblemSendGrade
    hardestCurrentIndoorRouteSendGrade
    hardestCurrentIndoorProblemSendGrade
  }
  ${basicUserFragment}
  ${profileFragment}
`

export const UserQuery = gql`
  query UserQuery($slug: String!) {
    user(slug: $slug) {
      ...fullUserFragment
    }
  }
  ${fullUserFragment}
`

export const LoggedInUserQuery = gql`
  query LoggedInUserQuery($buildNumber: Int) {
    loggedInUser(buildNumber: $buildNumber) {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`

export const GET_GYMS_USER_FOLLOWS = gql`
  query GetGymsUserFollows {
    getGymsUserFollows {
      _id
      slug
      name
      isOverlappingGrades
      routeGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
      problemGradeColors {
        color
        name
        minGrade
        maxGrade
        image
      }
    }
  }
`

export const MANAGED_SETTERS = gql`
  query ManagedSetters {
    loggedInUser {
      managedSetters {
        ...basicUserFragment
      }
    }
  }
  ${basicUserFragment}
`

export const GET_USER_NOTIFICATIONS = gql`
  query GetUserNotifications {
    loggedInUser {
      _id
      notifications {
        _id
        users {
          ...basicUserFragment
        }
        name
        noteType
        link
        isSeen
        isOpened
        date
      }
    }
  }
  ${basicUserFragment}
`

export const GET_USER_ACHIEVEMENTS = gql`
  query GetUserAchievements(
    $userId: String
    $limit: Int
    $isByImportance: Boolean
  ) {
    getUserAchievements(
      userId: $userId
      limit: $limit
      isByImportance: $isByImportance
    ) {
      _id
      achievements {
        ...achievementFragment
      }
      numSends
      numFinishedWorkouts
      numCreatedRoutes
      numCreatedProblems
    }
  }
  ${achievementFragment}
`

export const GET_USER_GYM_ACHIEVEMENTS = gql`
  query GetUserGymAchievements($gymId: String) {
    getUserGymAchievements(gymId: $gymId) {
      ...achievementFragment
    }
  }
  ${achievementFragment}
`

export const GET_USER_GUIDE_ACHIEVEMENTS = gql`
  query GetUserGuideAchievements($guideId: String) {
    getUserGuideAchievements(guideId: $guideId) {
      ...achievementFragment
    }
  }
  ${achievementFragment}
`

// uses @connection directive:
// https://www.apollographql.com/docs/react/advanced/caching.html#connection-directive
export const AthletesSearchQuery = gql`
  query AthletesSearchQuery($searchStr: String!, $cursor: String) {
    athletesSearch(searchStr: $searchStr, cursor: $cursor)
      @connection(key: "athletesSearch", filter: ["searchStr"]) {
      cursor
      hasMore
      athletes {
        ...basicUserFragment
        lastLoggedIn
        followers {
          _id
        }
        following {
          _id
        }
        blockers {
          _id
        }
        blocking {
          _id
        }
        profile {
          picture
        }
        gymsFollowing
        totalAscents
        gymMembershipRewards {
          gymId
        }
      }
    }
  }
  ${basicUserFragment}
`

export const FRIENDS = gql`
  query Friends($searchStr: String!, $cursor: String) {
    friends(searchStr: $searchStr, cursor: $cursor) {
      cursor
      hasMore
      athletes {
        ...basicUserFragment
      }
    }
  }
  ${basicUserFragment}
`

export const FollowingListQuery = gql`
  query FollowingListQuery($slug: String!, $cursor: String) {
    followingList(slug: $slug, cursor: $cursor) {
      cursor
      hasMore
      following {
        ...basicUserFragment
      }
    }
  }
  ${basicUserFragment}
`

export const FollowersListQuery = gql`
  query FollowersListQuery($slug: String!, $cursor: String) {
    followersList(slug: $slug, cursor: $cursor) {
      cursor
      hasMore
      followers {
        ...basicUserFragment
      }
    }
  }
  ${basicUserFragment}
`

export const authenticateSocialMutate = gql`
  mutation authenticateSocialMutate(
    $platform: String
    $token: String
    $firstName: String
    $lastName: String
    $isWeb: Boolean
    $agreedToTOUandPP: Boolean
    $picture: String
  ) {
    authenticateSocial(
      platform: $platform
      token: $token
      firstName: $firstName
      lastName: $lastName
      isWeb: $isWeb
      agreedToTOUandPP: $agreedToTOUandPP
      picture: $picture
    ) {
      token
      error
    }
  }
`

export const manualLoginMutate = gql`
  mutation manualLoginMutate($credentials: CredentialsInput) {
    manualLogin(credentials: $credentials) {
      token
      error
    }
  }
`

export const manualRegisterMutate = gql`
  mutation manualRegisterMutate($credentials: CredentialsInput) {
    manualRegister(credentials: $credentials) {
      token
      error
    }
  }
`

export const verifyUserMutate = gql`
  mutation verifyUserMutate($slug: String!, $token: String!) {
    verifyUser(slug: $slug, token: $token) {
      _id
    }
  }
`

export const sendVerifyEmailMutate = gql`
  mutation sendVerifyEmailMutate {
    sendVerifyEmail {
      _id
    }
  }
`

export const sendForgotPasswordLinkMutate = gql`
  mutation sendForgotPasswordLinkMutate($email: String) {
    sendForgotPasswordLink(email: $email) {
      isSuccessful
      error
    }
  }
`

export const sendResetPasswordMutate = gql`
  mutation sendResetPasswordMutate($password: String, $token: String) {
    sendResetPassword(password: $password, token: $token) {
      token
      error
    }
  }
`

export const EDIT_USER = gql`
  mutation EditUser($user: UserInput!) {
    editUser(user: $user) {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`

export const saveUserLocationMutate = gql`
  mutation saveUserLocationMutate(
    $id: String!
    $latitude: Float!
    $longitude: Float!
  ) {
    saveUserLocation(id: $id, latitude: $latitude, longitude: $longitude) {
      isSuccessful
      error
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($userId: String!, $password: String) {
    deleteUser(userId: $userId, password: $password) {
      isSuccessful
      error
    }
  }
`

export const followMutate = gql`
  mutation followMutate($followerSlug: String!, $followeeSlug: String!) {
    follow(followerSlug: $followerSlug, followeeSlug: $followeeSlug) {
      follower {
        ...basicUserFragment
        awaitingFollowers
        awaitingFollowing
        following {
          ...basicUserFragment
        }
      }
      followee {
        ...basicUserFragment
        awaitingFollowers
        awaitingFollowing
        followers {
          ...basicUserFragment
        }
      }
    }
  }
  ${basicUserFragment}
`

export const unfollowMutate = gql`
  mutation unfollowMutate($followerSlug: String!, $followeeSlug: String!) {
    unfollow(followerSlug: $followerSlug, followeeSlug: $followeeSlug) {
      follower {
        ...basicUserFragment
        awaitingFollowers
        awaitingFollowing
        following {
          ...basicUserFragment
        }
      }
      followee {
        ...basicUserFragment
        awaitingFollowers
        awaitingFollowing
        followers {
          ...basicUserFragment
        }
      }
    }
  }
  ${basicUserFragment}
`

export const approveFollowingMutate = gql`
  mutation approveFollowingMutate($followerSlug: String!) {
    approveFollowing(followerSlug: $followerSlug) {
      follower {
        ...basicUserFragment
        following {
          ...basicUserFragment
        }
      }
      followee {
        ...basicUserFragment
        followers {
          ...basicUserFragment
        }
      }
    }
  }
  ${basicUserFragment}
`

export const declineFollowingMutate = gql`
  mutation declineFollowingMutate($followerSlug: String!) {
    declineFollowing(followerSlug: $followerSlug) {
      follower {
        ...basicUserFragment
        following {
          ...basicUserFragment
        }
      }
      followee {
        ...basicUserFragment
        followers {
          ...basicUserFragment
        }
      }
    }
  }
  ${basicUserFragment}
`

export const blockMutate = gql`
  mutation blockMutate($blockerSlug: String!, $blockeeSlug: String!) {
    block(blockerSlug: $blockerSlug, blockeeSlug: $blockeeSlug) {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`

export const unblockMutate = gql`
  mutation unblockMutate($blockerSlug: String!, $blockeeSlug: String!) {
    unblock(blockerSlug: $blockerSlug, blockeeSlug: $blockeeSlug) {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`

export const reportUserMutate = gql`
  mutation reportUserMutate($userSlug: String!, $message: String!) {
    reportUser(userSlug: $userSlug, message: $message) {
      _id
      slug
    }
  }
`

export const contactPebbleMutate = gql`
  mutation contactPebbleMutate(
    $email: String!
    $message: String!
    $subject: String
    $source: String
  ) {
    contactPebble(
      email: $email
      message: $message
      subject: $subject
      source: $source
    ) {
      isSuccessful
      error
    }
  }
`

export const ADD_USER_NOTIFICATION_TOKEN = gql`
  mutation AddUserNotificationToken($token: String!, $os: String!) {
    addUserNotificationToken(token: $token, os: $os) {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`
export const RESET_NOTIFICATIONS = gql`
  mutation ResetNotifications {
    resetNotifications {
      _id
      notifications {
        _id
        isSeen
      }
    }
  }
`

export const SET_NOTIFICATION_OPENED = gql`
  mutation SetNotificationOpened($link: String, $_id: String) {
    setNotificationOpened(link: $link, _id: $_id) {
      _id
      notifications {
        _id
        isOpened
      }
    }
  }
`

export const OPT_SHARE_GYM_DATA = gql`
  mutation OptShareGymData($isSharing: Boolean!) {
    optShareGymData(isSharing: $isSharing) {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`

export const GET_USER_POINTS = gql`
  query GetUserPoints($userId: String!, $fromDate: Date!) {
    getUserPoints(userId: $userId, fromDate: $fromDate) {
      problemPoints
      routePoints
    }
  }
`

export const CONTACT_USER = gql`
  mutation ContactUser($userId: String!, $message: String!) {
    contactUser(userId: $userId, message: $message) {
      _id
    }
  }
`

export const DOWNLOAD_USER = gql`
  query DownloadUser($slug: String!) {
    user(slug: $slug) {
      _id
      firstName
      lastName
      name
      slug
      earliestMediaDate
      earliestSendDate
      email
      firstName
      hasClimbTags
      hasMedia
      hasPhotoTags
      hasRoutesAndProblems
      hasSends
      isAdmin
      isBanned
      isFrenchGrades
      isPrivate
      isSponsor
      lastName
      latestMediaDate
      latestSendDate
      totalAscents
      profile {
        picture
        gender
        location
        website
        bio
        height
        weight
        age
        dob
        shoes
        latestShoes
        harness
        rope
        cover {
          slug
        }
      }
      allSends {
        sendType
        name
        type
        grade
        tries
        date
        problem {
          name
          grade
        }
        route {
          name
          grade
        }
        isIndoor
      }
      allMedia {
        name
        type
        url
      }
    }
  }
`

export const SAVE_USER_RFID_TAGS = gql`
  mutation SaveUserRFIDTags($rfid: String!) {
    saveUserRFIDTags(rfid: $rfid) {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`

export const CHECK_SLUG_AVAILABILITY = gql`
  mutation CheckSlugAvailability($slug: String!) {
    checkSlugAvailability(slug: $slug) {
      isAvailable
    }
  }
`

export const SETUP_LINK_CLIMB_TO_WRISTBAND = gql`
  query SetupLinkClimbToWristband($climbId: String!, $climbType: String!) {
    setupLinkClimbToWristband(climbId: $climbId, climbType: $climbType) {
      isSuccessful
      error
    }
  }
`

export const TAB_BADGE_NUMBERS = gql`
  query TabBadgeNumbers {
    tabBadgeNumbers {
      label
      value
    }
  }
`

export const CREATE_STRAVA_TOKENS = gql`
  mutation CreateStravaTokens($code: String!) {
    createStravaTokens(code: $code) {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`

export const DISCONNECT_STRAVA = gql`
  mutation DisconnectStrava {
    disconnectStrava {
      ...loggedInUserFragment
    }
  }
  ${loggedInUserFragment}
`
export const CLEAR_WRISTBAND_FROM_USER = gql`
  mutation ClearWristbandFromUser($rfid: String!) {
    clearWristbandFromUser(rfid: $rfid) {
      isSuccessful
    }
  }
`

export const GET_USER_FROM_WRISTBAND = gql`
  query GetUserFromWristband($rfid: String!) {
    getUserFromWristband(rfid: $rfid) {
      ...basicUserFragment
    }
  }
  ${basicUserFragment}
`

export const ASSIGN_USER_TO_WRISTBAND = gql`
  mutation AssignUserToWristband($rfid: String!, $userId: String!) {
    assignUserToWristband(rfid: $rfid, userId: $userId) {
      isSuccessful
    }
  }
`

export const GET_MEMBER_POINTS = gql`
  query loggedInUser {
    loggedInUser {
      _id
      rewardsEligibility {
        isEligibleForRewardPoints
        isEligibleForRetailCredits
        memberName
      }
      gymMembershipRewards {
        _id
        gymGroup {
          _id
          name
        }
        rewardPoints
      }
    }
  }
`

export const VALIDATE_USER_IDS = gql`
  mutation ValidateUserIds($userIds: [String]!) {
    validateUserIds(userIds: $userIds) {
      userIds
      userIdsNotFound
      users {
        ...basicUserFragment
      }
    }
  }
  ${basicUserFragment}
`

export const USER_COMPS = gql`
  query UserComps($slug: String!) {
    user(slug: $slug) {
      _id
      comps {
        ...basicCompFragment
      }
    }
  }
  ${basicCompFragment}
`

// export const GYM_FINDING_PARTNER = gql`
//   query GymFindingPartner {
//     loggedInUser {
//       _id
//       gymFindingPartnerAt {
//         ...basicGymFragment
//       }
//       gymFindingPartnerAtDate
//       gymFindingPartnerAtMinutes
//     }
//   }
//   ${basicGymFragment}
// `

import { darkify } from "../utilities/colors"

export type ColorPaletteType = {
  orangeColor: string
  orangeTransparent: string
  orangeColorHover: string
  yellowColor: string
  redColor: string
  blueColor: string
  blueTransparent: string
  blueOverlay: string
  blueBackground: string
  headingColor: string
  headingTransparent: string
  headingLineColor: string
  blueColorHover: string
  cardBorder: string
  lightGrey: string
  lightBkgrnd: string
  greyColor: string
  whiteColor: string
  whiteColorHover: string
  overlayColor: string
  overlayAndroid: string
  textColor: string
  textLightColor: string
  overlayBlack: string
  btnBorderColor: string
  siteBGColor: string
  bgColor: string
  darkBGColor: string
  darkerBGColor: string
  darkestBGColor: string
  cardBorderLink: string
  fbColor: string
  ggColor: string
  stravaColor: string
  tabColor: string
  whiteOverlay: string
  oldCardColor: string
  selectedHeadingColor: string
  placeholderColor: string
  inputBackgroundColor: string
  startGradient: string
  endGradient: string
  insetColor: string
  chartColors: string[]
  openBetaColor: string
}

const lightColors = {
  orangeColor: "#FF6e00",
  orangeTransparent: "rgba(255, 110, 0, 0.5)",
  orangeColorHover: "#EE6A06",
  yellowColor: "#FFA630",
  redColor: "#ff0000",
  blueColor: "#03A1F9",
  blueTransparent: "rgba(3, 161, 249, 0.5)",
  blueOverlay: "rgba(3, 169, 244, 0.8)",
  blueBackground: "rgba(3, 169, 244, 0.05)",
  headingColor: "#20353F",
  headingTransparent: "rgba(32, 53, 63, 0.5)",
  headingLineColor: "#B6D7EB",
  headingLineTransparent: "rgba(182, 215, 235, 0.5)",
  blueColorHover: "#0396E7",
  cardBorder: "#dddddd",
  lightGrey: "#cfcfcf",
  lightBkgrnd: "#efefef",
  greyColor: "#9B9B9B",
  whiteColor: "#ffffff",
  whiteColorHover: "#f5f5f5",
  overlayColor: "rgba(18, 61, 93, .8)",
  overlayAndroid: "rgba(18, 61, 93, .9)",
  textColor: "rgba(0, 0, 0, .6)",
  textLightColor: "rgba(0, 0, 0, .4)",
  overlayBlack: "rgba(0, 0, 0, .2)",
  btnBorderColor: "#cedae0",
  siteBGColor: "#f1f4f5",
  bgColor: "#E9EEEF",
  darkBGColor: "#D4E1EA",
  darkerBGColor: "#D4E1EA",
  darkestBGColor: "#B6D7EB",
  cardBorderLink: "#35B3FA",
  fbColor: "#3a5998",
  ggColor: "#d84a38",
  stravaColor: "#fc4c02",
  tabColor: "#e9e9ee",
  whiteOverlay: "rgba(255, 255, 255, .8)",
  oldCardColor: "rgb(245, 245, 245)",
  selectedHeadingColor: "#996309",
  placeholderColor: "#8CB0C2",
  inputBackgroundColor: "rgba(0, 0, 0, 0.04)",
  startGradient: "#D6EFFE",
  endGradient: "#A2C7DD",
  sessionStatsGradSquare1: "#C3E1F2",
  sessionStatsGradSquare2: "#BAD9EC",
  insetColor: "#cadbe3",
  chartColors: [
    ,
    "#03a9f4",
    "#ff8900",
    "#3F749B",
    "#66cc96",
    "#6d3399",
    "#717cd0",
    "#1d5857",
    "#d47dbb",
    "#1f541c",
    "#2a767e",
    "#a19636",
    "#914930",
    "#6f5257",
    "#2a7e72",
    "#a437a4",
    "#34499d",
    "#8e2f5e",
    "#262973",
    "#3d1423",
    "#393013",
  ],
  openBetaColor: "#f15f41",
}

const excludedColorKeys = ["fbColor", "ggColor"]

const darkColors = Object.keys(lightColors).reduce((acc, key) => {
  return {
    ...acc,
    [key]:
      key === "whiteColor"
        ? "#282C2E"
        : key === "headingLineColor"
          ? "#525657"
          : key === "blueColor"
            ? "#40B9FA"
            : key === "openBetaColor"
              ? "#f15f41"
              : key === "insetColor"
                ? "#191a1a"
                : typeof key === "string" && !excludedColorKeys.includes(key)
                  ? darkify(lightColors[key])
                  : lightColors[key],
  }
}, lightColors)

const colors: {
  light: ColorPaletteType
  dark: ColorPaletteType
} = {
  light: lightColors,
  dark: darkColors,
}

export function getColorPalette (
  colorScheme: "light" | "dark" | null = "light",
): ColorPaletteType {
  return colors[colorScheme]
}

// a way to override all the weighted colors from chakra that are sometimes necessary
export const brandColorsWeightedLight = Object.keys(colors.light).reduce(
  (acc, key) => {
    return {
      ...acc,
      [key]: {
        50: colors[key],
        100: colors[key],
        200: colors[key],
        300: colors[key],
        400: colors[key],
        500: colors[key],
        600: colors[key],
        700: colors[key],
        800: colors[key],
        900: colors[key],
      },
    }
  },
  {},
)

// a way to override all the weighted colors from chakra that are sometimes necessary
export const brandColorsWeightedDark = Object.keys(colors.dark).reduce(
  (acc, key) => {
    return {
      ...acc,
      [key]: {
        50: colors[key],
        100: colors[key],
        200: colors[key],
        300: colors[key],
        400: colors[key],
        500: colors[key],
        600: colors[key],
        700: colors[key],
        800: colors[key],
        900: colors[key],
      },
    }
  },
  {},
)

export default colors
